<template>
  <div class="detail-container mt-80-mb-140">
    <div class="container">
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-8 col-md-8 news">
          <div class="title">{{ detail.title }}</div>
          <div class="small-title">
            {{ detail.create_at }}
            <span style="margin-left: 10px">{{ detail.comfrom }}</span>
            <span style="margin-left: 10px">浏览量 {{ detail.hits }}</span>
          </div>
          <div class="content" v-html="detail.content"></div>
        </div>
        <div class="col-0 col-xl-4 col-xxl-4 col-md-4 slider">
          <div class="box">
            <div class="slider-title">最新发布</div>
            <div
              class="slider-list d-flex align-items-center"
              v-for="item in detail.newest"
              :key="item"
              @click="goToNewsDetail(item.newsid)"
            >
              <div class="icon"></div>
              <div class="text">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onActivated, ref, onMounted, computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getNewsDetail } from '../../../server/news'
const emit = defineEmits(['hideTab'])
onActivated(() => {
  emit('hideTab')
})
const route = useRoute()
const router = useRouter()

const detail = ref('')
const newsid = computed(() => route.query.newsid)

onMounted(() => {
  fetchDetail()
})

const fetchDetail = async () => {
  detail.value = await getNewsDetail(newsid.value)
}

const goToNewsDetail = (newsid) => {
  router.replace({
    path: route.fullPath,
    query: { newsid: newsid }
  })
}

watch(
  () => newsid.value,
  (newVal) => {
    if (newVal === undefined) {
      return
    }
    fetchDetail()
  }
)
</script>

<style lang="stylus" scoped>
.news
  .title
    font-size: 38px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #10366B;
    margin-bottom 20px
    @media screen and (max-width 768px) {
      font-size 25px
      margin-bottom 5px
    }
  .small-title
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    margin-bottom 25px
    @media screen and (max-width 768px) {
      font-size 14px
      margin-bottom 10px
    }

.slider
  @media screen and (max-width 768px) {
    display none
  }
  .box
    background #fff
    padding 30px 18px
    border-radius: 5px;
    .slider-title
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333
      margin-bottom 20px
    .slider-list
      font-size: 16px;
      cursor pointer
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-bottom 20px

      &:last-child
        margin-bottom 0
      .icon
        width 3px
        height 3px
        background #000
        margin-right 6px
      .text
        flex 1
        overflow hidden
        text-overflow ellipsis
        word-break break-all
        white-space nowrap
</style>
